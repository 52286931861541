import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { graphql } from 'gatsby';
import { colors } from "../utils/presets";
export const pageQuery = graphql`
query Bios {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`;
const layoutProps = {
  pageQuery
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);

    this.layout = ({
      children,
      ...props
    }) => <div css={`margin-top: 3rem;`}>
    {children}
  </div>;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Nicole Asselin`}</MDXTag>{` is a designer and educator based in Durham North Carolina. Using an interdisciplinary lens,
her work focuses on the intersection of craft, technology and the natural world. She has been
practicing the Book of Changes for over 10 years and is excited to share its subtle wisdom.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Toviah Narva`}</MDXTag>{` is a Psychiatric and Adult Health Nurse Practitioner practicing at an LGBTQ clinic in New York.
She was introduced to the I Ching by her mother, and has been practicing it ever since in her personal and family life.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Contact: info@sixtyfourchanges.com`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "title": "Bios"
};
    